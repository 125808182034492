<template>
  <div class="activity-detail-info-card" v-if="data">

    <div class="activity-detail-info-card-header">
      <div class="career-fair-name">{{data.career_fair_name}}</div>
      <a :href="ats" target="__blank" class="btn">企业报名</a>
    </div>

    <div class="activity-detail-info-card-content">
      <el-image style="width: 297px; height: 167px" :src="data.des_link" fit="cover" />
      <div class="right">
        <div class="list-box">
          <div class="list-item">主办单位：{{data.valley_name}}</div>
          <div class="list-item">活动地点：{{data.address}}</div>
          <div class="list-item">报名时间：{{applyTime}}</div>
          <div class="list-item">活动时间：{{time}}</div>
        </div>
        <!-- <div>
          <div class="list-item">参会企业：{{}}家</div>
          <div class="list-item">热招职位：{{}}个</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {ats} from "constants/routerLink"
export default {
  name:"",
  props:["data"],
  data:()=>({
    
  }),
  methods:{},
  computed:{
    ats:()=>ats,
    applyTime(){
      if(this.data.apply_time){
        if(this.data.ddl_time) return `${this.$dayjs(this.data.apply_time).format("YYYY.MM.DD")} - ${this.$dayjs(this.data.ddl_time).format("YYYY.MM.DD")}`
        else return `${this.$dayjs(this.data.apply_time).format("YYYY.MM.DD")} - 待定`
      }else{
        if(this.data.ddl_time) return `${this.$dayjs(this.data.ddl_time).format("YYYY.MM.DD")} 结束`
        else return `待定`
      }
    },
    time(){
      if(this.data.start_time){
        if(this.data.end_time) return `${this.$dayjs(this.data.start_time).format("YYYY.MM.DD")} - ${this.$dayjs(this.data.end_time).format("YYYY.MM.DD")}`
        else return `${this.$dayjs(this.data.start_time).format("YYYY.MM.DD")} - 待定`
      }else{
        if(this.data.end_time) return `${this.$dayjs(this.data.end_time).format("YYYY.MM.DD")} 结束`
        else return `待定`
      }
    }
  },
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.activity-detail-info-card{
  font-size: $medium;
  color: $cardsubTextColor;
  .activity-detail-info-card-header{
    background-color: $defaultBg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px 26px;
    box-shadow: 0px 0px 18px $shadowColor;
    margin-bottom: 26px;
    border-radius: 0px 0px $borderRadius $borderRadius;
    .career-fair-name{
      font-size: $large;
      font-weight: $weight-medium;
      
    }
  }
  
  .activity-detail-info-card-content{
    padding: 20px 26px;
    display: flex;
    background-color: $defaultBg;
    border-radius:$borderRadius;
    box-shadow: 0px 0px 18px $shadowColor;
    width: 100%;
    >.right{
      margin-left: 100px;
      height: 100%;
      .list-box{
        padding-top: 10px;
      }
      .list-item{
        height: 100%;
        margin-bottom: 23px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      
    }
  }


  .btn{
    width: 100px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: $linear;
    color: $defaultColor;
    border-radius: 999px;
    cursor: pointer;
  }
}

</style>