<template>
  <div class="activity-tetail page-container">
    <ActivityInfoCard :data="data" />
    <DetailTabNav :curIndex="curIndex" :list="list" @change="toggle" />
    <div class="activity-introduction" v-if="curIndex === 0" v-html="data.description">

    </div>
    <div v-if="curIndex === 1">
      <CompanyListWrapper style="margin-top:26px" :pagination="true" :ajaxApi="'getCareerFairCompanys'" :nums="15"
        :cfId="data.career_fair_id" />
    </div>
    <div v-if="curIndex === 2">
      <JobListWrapper cardVersion="new" :pagination="true" style="margin-top:26px" :ajaxApi="'getCareerFairJobs'"
        :nums="15" :cfId="data.career_fair_id" />
    </div>
  </div>
</template>
<script>
import ActivityInfoCard from "components/activity/detail/activityDetailInfo"
import CompanyListWrapper from "components/company/companyListWrapper"
import DetailTabNav from "components/detailTabNav"
import JobListWrapper from "components/jobs/jobListWrapper";
import { activityDetailNavList } from "constants/publicData"
export default {
  name: "CompanyDetail",
  data: () => ({
    data: null,
    curIndex: 0,
  }),
  computed: {
    list: () => activityDetailNavList,
  },
  created() {
    this.getData()
  },
  methods: {
    toggle(i) {
      this.curIndex = i
    },
    getData() {
      this.$axiosRequest({
        name: "getActivityDetail",
        ids: this.$route.params.id,
      })
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {},
  components: {
    ActivityInfoCard,
    CompanyListWrapper,
    DetailTabNav,
    JobListWrapper
  }

}
</script>
<style lang="scss">
@import "constants/style/public";

.activity-tetail {
  text-align: left;
  padding-bottom: 60px;
  font-size: $medium;

  .activity-introduction {
    padding: 10px 26px;
    min-height: 300px;
    background-color: $defaultBg;
    margin-top: 25px;
    box-shadow: 0px 0px 18px $shadowColor;
    border-radius: $borderRadius;

  }
}
</style>